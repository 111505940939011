<template>
  <div class="column">
    <div class="top_text">全国各项指标综合评分</div>
    <div id="barChart" class="topBar"></div>
    <div class="row">
      <div class="column1">
        <div class="row">
          <div v-if="monthType == 1" class="month_select">
            <div class="text1">{{ monthList ? monthList[0] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-else class="month_normal" @click="monthClick(1)">
            <div class="text1">{{ monthList ? monthList[0] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-if="monthType == 2" class="month_select">
            <div class="text1">{{ monthList ? monthList[1] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-else class="month_normal" @click="monthClick(2)">
            <div class="text1">{{ monthList ? monthList[1] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-if="monthType == 3" class="month_select">
            <div class="text1">{{ monthList ? monthList[2] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-else class="month_normal" @click="monthClick(3)">
            <div class="text1">{{ monthList ? monthList[2] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-if="monthType == 4" class="month_select">
            <div class="text1">{{ monthList ? monthList[3] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-else class="month_normal" @click="monthClick(4)">
            <div class="text1">{{ monthList ? monthList[3] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-if="monthType == 5" class="month_select">
            <div class="text1">{{ monthList ? monthList[4] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-else class="month_normal" @click="monthClick(5)">
            <div class="text1">{{ monthList ? monthList[4] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-if="monthType == 6" class="month_select">
            <div class="text1">{{ monthList ? monthList[5] : "" }}</div>
            <div class="text2">月</div>
          </div>
          <div v-else class="month_normal" @click="monthClick(6)">
            <div class="text1">{{ monthList ? monthList[5] : "" }}</div>
            <div class="text2">月</div>
          </div>
        </div>
      </div>
    </div>
    <div class="title mt26">幼儿运动处方</div>
    <img class="pes" src="../../assets/img/home/home/PES/pes.png" />
    <div class="content mt15">
      遵循英国国家级幼儿园学前教育体系,以英足总青少年发展管理体系为基础
    </div>
    <div class="content">引入英国原创视频课程</div>
    <div class="baseLine mt72">
      <div class="width514">
        <div class="top_lay">
          <div class="top_bg pos_abs">
            <div class="middle">上月体质综合评分</div>
          </div>
        </div>
        <div class="hint1">上月体质测试全国综合评分对比园所综合评分</div>
        <div class="num_lay mt36">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_judge_height ? rateData.B_judge_height[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">身高</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_judge_height && rateData.B_judge_height[1]"
              class="text"
              >{{ rateData.B_judge_height[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_judge_weight ? rateData.B_judge_weight[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">体重</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_judge_weight && rateData.B_judge_weight[1]"
              class="text"
              >{{ rateData.B_judge_weight[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_judge_eye ? rateData.B_judge_eye[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">视力</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_judge_eye && rateData.B_judge_eye[1]"
              class="text"
              >{{ rateData.B_judge_eye[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_judge_arm ? rateData.B_judge_arm[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">臂展</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_judge_arm && rateData.B_judge_arm[1]"
              class="text"
              >{{ rateData.B_judge_arm[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_judge_lung ? rateData.B_judge_lung[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">肺活量</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_judge_lung && rateData.B_judge_lung[1]"
              class="text"
              >{{ rateData.B_judge_lung[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_judge_bust ? rateData.B_judge_bust[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">胸围</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_judge_bust && rateData.B_judge_bust[1]"
              class="text"
              >{{ rateData.B_judge_bust[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_judge_flexion ? rateData.B_judge_flexion[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">体前屈</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_judge_flexion && rateData.B_judge_flexion[1]"
              class="text"
              >{{ rateData.B_judge_flexion[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
      </div>
      <div class="width514 ml55">
        <div class="top_lay">
          <div class="top_bg pos_abs">
            <div class="middle">上月运动综合评分</div>
          </div>
        </div>
        <div class="hint1">上月运动测试全国综合评分对比园所综合评分</div>
        <div class="num_lay mt36">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_judge_run ? rateData.S_judge_run[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">跑动</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_judge_run && rateData.S_judge_run[1]"
              class="text"
              >{{ rateData.S_judge_run[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_judge_jump ? rateData.S_judge_jump[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">跳跃</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_judge_jump && rateData.S_judge_jump[1]"
              class="text"
              >{{ rateData.S_judge_jump[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_judge_agile ? rateData.S_judge_agile[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">敏捷</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_judge_agile && rateData.S_judge_agile[1]"
              class="text"
              >{{ rateData.S_judge_agile[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_judge_power ? rateData.S_judge_power[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">力量</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_judge_power && rateData.S_judge_power[1]"
              class="text"
              >{{ rateData.S_judge_power[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_judge_coordiate ? rateData.S_judge_coordiate[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">协调</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_judge_coordiate && rateData.S_judge_coordiate[1]"
              class="text"
              >{{ rateData.S_judge_coordiate[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_judge_balance ? rateData.S_judge_balance[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">平衡</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_judge_balance && rateData.S_judge_balance[1]"
              class="text"
              >{{ rateData.S_judge_balance[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_judge_ctrlHand ? rateData.S_judge_ctrlHand[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">物控手</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_judge_ctrlHand && rateData.S_judge_ctrlHand[1]"
              class="text"
              >{{ rateData.S_judge_ctrlHand[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_judge_ctrlFoot ? rateData.S_judge_ctrlFoot[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">物控脚</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_judge_ctrlFoot && rateData.S_judge_ctrlFoot[1]"
              class="text"
              >{{ rateData.S_judge_ctrlFoot[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
      </div>
    </div>
    <div id="analyseChart" class="analyseBar"></div>
    <div class="row width1400">
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[0] ? times[0].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[0]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[1] ? times[1].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[1]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[2] ? times[2].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[2]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[3] ? times[3].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[3]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[4] ? times[4].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[4]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[5] ? times[5].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[5]" class="red_text">未测试</div>
      </div>
    </div>
    <div class="row mt26">
      <div class="row">
        <div
          class="label ml32"
          @click="clickAnalyseType(1)"
          @mouseover="Mouseover1(1)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 1 || mouthAnalyseType == 1
                ? 'width62 ys_color1 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[0] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(2)"
          @mouseover="Mouseover1(2)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 2 || mouthAnalyseType == 2
                ? 'width62 ys_color2 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[1] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(3)"
          @mouseover="Mouseover1(3)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 3 || mouthAnalyseType == 3
                ? 'width62 ys_color3 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[2] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(4)"
          @mouseover="Mouseover1(4)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 4 || mouthAnalyseType == 4
                ? 'width62 ys_color4 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[3] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(5)"
          @mouseover="Mouseover1(5)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 5 || mouthAnalyseType == 5
                ? 'width62 ys_color5 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[4] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(6)"
          @mouseover="Mouseover1(6)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 6 || mouthAnalyseType == 6
                ? 'width62 ys_color6 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[5] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseType(7)"
          @mouseover="Mouseover1(7)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 7 || mouthAnalyseType == 7
                ? 'width62 ys_color7 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[6] }}
          </div>
        </div>

        <div
          class="label ml32"
          @click="clickAnalyseType(8)"
          @mouseover="Mouseover1(8)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 8 || mouthAnalyseType == 8
                ? 'width62 ys_color8 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[7] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(9)"
          @mouseover="Mouseover1(9)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 9 || mouthAnalyseType == 9
                ? 'width62 ys_color9 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[8] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(10)"
          @mouseover="Mouseover1(10)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 10 || mouthAnalyseType == 10
                ? 'width62 ys_color10 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[9] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(11)"
          @mouseover="Mouseover1(11)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 11 || mouthAnalyseType == 11
                ? 'width62 ys_color11 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[10] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(12)"
          @mouseover="Mouseover1(12)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 12 || mouthAnalyseType == 12
                ? 'width62 ys_color12 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[11] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(13)"
          @mouseover="Mouseover1(13)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 13 || mouthAnalyseType == 13
                ? 'width62 ys_color13 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[12] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseType(14)"
          @mouseover="Mouseover1(14)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 14 || mouthAnalyseType == 14
                ? 'width62 ys_color14 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[13] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseType(15)"
          @mouseover="Mouseover1(15)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 15 || mouthAnalyseType == 15
                ? 'width62 ys_color15 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[14] }}
          </div>
        </div>
      </div>
    </div>
    <div class="ys_name">{{ ysName }}</div>
    <div class="result">分析结果：{{ message ? message : "暂无" }}</div>
    <div class="h240"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "childrenCf",
  components: {},
  data() {
    return {
      charts: null,
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0,
      value5: 0,
      value6: 0,
      value7: 0,
      value8: 0,
      value9: 0,
      value10: 0,
      value11: 0,
      value12: 0,
      value13: 0,
      value14: 0,
      value15: 0,
      monthType: 1,
      analyseCharts: null,
      indicator: [],
      indicator1: [
        {
          name: "身高\nCM",
          max: 100,
        },
        { name: "体重\nKG", max: 100 },
        { name: "视力", max: 100 },
        { name: "臂展\nCM", max: 100 },
        { name: "肺活量\nML", max: 100 },
        { name: "胸围\nCM", max: 100 },
        { name: "体前屈\nCM", max: 100 },
      ],
      indicator2: [
        { name: "跑动\nS", max: 100 },
        { name: "跳跃\nS", max: 100 },
        { name: "敏捷\nS", max: 100 },
        { name: "力量\nS", max: 100 },
        { name: "协调\nS", max: 100 },
        { name: "平衡\nS", max: 100 },
        { name: "物控手\n个", max: 100 },
        { name: "物控脚\nS", max: 100 },
      ],
      qgValue: [],
      ysValue: [],
      analyseType: 1,
      analyseMonthType: 1,
      topData: [],
      topQgData: [],
      monthList: [],
      rateData: {},
      analyseData: [],
      message: "",
      ysName: "",
      names1: [
        "身高",
        "体重",
        "视力",
        "胸围",
        "体前屈",
        "臂展",
        "肺活量",
        "跑动",
        "跳跃",
        "敏捷",
        "平衡",
        "协调",
        "力量",
        "物控手",
        "物控脚",
      ],
      qgData: [],
      analyseValue: {},
      qgHeight: [],
      ysHeight: [],
      currentAnalyseType: 1,
      mouthAnalyseType: -1,
      times: [],
      leftColor: "#00b29d",
      rightColor: "rgba(192, 40, 27, 1)",
    };
  },

  methods: {
    clickAnalyseType(index) {
      this.currentAnalyseType = index;
      this.qgData = [];
      this.analyseData = [];
      this.qgHeight=[];
      this.ysHeight=[];
      if (index == 1) {
        this.leftColor = "#00b29d";
        this.rightColor = "rgba(192, 40, 27, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].B_judge_height[0]);
            this.analyseData.push(this.analyseValue[i].B_judge_height[1]);
            this.qgHeight.push(this.analyseValue[i].B_judge_height_kd[0]);
            this.ysHeight.push(this.analyseValue[i].B_judge_height_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 2) {
        this.leftColor = "#88391e";
        this.rightColor = "rgba(64, 209, 0, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].B_judge_weight[0]);
            this.analyseData.push(this.analyseValue[i].B_judge_weight[1]);
            this.qgHeight.push(this.analyseValue[i].B_judge_weight_kd[0]);
            this.ysHeight.push(this.analyseValue[i].B_judge_weight_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 3) {
        this.leftColor = "#344f00";
        this.rightColor = "rgba(0, 168, 193, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].B_judge_eye[0]);
            this.analyseData.push(this.analyseValue[i].B_judge_eye[1]);
            this.qgHeight.push(this.analyseValue[i].B_judge_eye_kd[0]);
            this.ysHeight.push(this.analyseValue[i].B_judge_eye_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 4) {
        this.leftColor = "#002055";
        this.rightColor = "rgba(224, 200, 0, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].B_judge_arm[0]);
            this.analyseData.push(this.analyseValue[i].B_judge_arm[1]);
            this.qgHeight.push(this.analyseValue[i].B_judge_arm_kd[0]);
            this.ysHeight.push(this.analyseValue[i].B_judge_arm_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 5) {
        this.leftColor = "#005047";
        this.rightColor = "rgba(244, 138, 0, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].B_judge_lung[0]);
            this.analyseData.push(this.analyseValue[i].B_judge_lung[1]);
            this.qgHeight.push(this.analyseValue[i].B_judge_lung_kd[0]);
            this.ysHeight.push(this.analyseValue[i].B_judge_lung_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 6) {
        this.leftColor = "#500009";
        this.rightColor = "rgba(39, 0, 237, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].B_judge_bust[0]);
            this.analyseData.push(this.analyseValue[i].B_judge_bust[1]);
            this.qgHeight.push(this.analyseValue[i].B_judge_bust_kd[0]);
            this.ysHeight.push(this.analyseValue[i].B_judge_bust_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 7) {
        this.leftColor = "#41cbb9";
        this.rightColor = "rgba(214, 0, 253, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].B_judge_flexion[0]);
            this.analyseData.push(this.analyseValue[i].B_judge_flexion[1]);
            this.qgHeight.push(this.analyseValue[i].B_judge_flexion_kd[0]);
            this.ysHeight.push(this.analyseValue[i].B_judge_flexion_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 8) {
        this.leftColor = "#a37d8c";
        this.rightColor = "rgba(151, 32, 79, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].S_judge_run[0]);
            this.analyseData.push(this.analyseValue[i].S_judge_run[1]);
            this.qgHeight.push(this.analyseValue[i].S_judge_run_kd[0]);
            this.ysHeight.push(this.analyseValue[i].S_judge_run_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 9) {
        this.leftColor = "#55242a";
        this.rightColor = "rgba(150, 36, 49, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].S_judge_jump[0]);
            this.analyseData.push(this.analyseValue[i].S_judge_jump[1]);
            this.qgHeight.push(this.analyseValue[i].S_judge_jump_kd[0]);
            this.ysHeight.push(this.analyseValue[i].S_judge_jump_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 10) {
        this.leftColor = "#2e7aa2";
        this.rightColor = "#2ea27a";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].S_judge_agile[0]);
            this.analyseData.push(this.analyseValue[i].S_judge_agile[1]);
            this.qgHeight.push(this.analyseValue[i].S_judge_agile_kd[0]);
            this.ysHeight.push(this.analyseValue[i].S_judge_agile_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 11) {
        this.leftColor = "#263d69";
        this.rightColor = "#1f4487";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].S_judge_power[0]);
            this.analyseData.push(this.analyseValue[i].S_judge_power[1]);
            this.qgHeight.push(this.analyseValue[i].S_judge_power_kd[0]);
            this.ysHeight.push(this.analyseValue[i].S_judge_power_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 12) {
        this.leftColor = "#705d17";
        this.rightColor = "rgba(182, 150, 35, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].S_judge_coordiate) {
            this.qgData.push(this.analyseValue[i].S_judge_coordiate[0]);
            this.analyseData.push(this.analyseValue[i].S_judge_coordiate[1]);
            this.qgHeight.push(this.analyseValue[i].S_judge_coordiate_kd[0]);
            this.ysHeight.push(this.analyseValue[i].S_judge_coordiate_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 13) {
        this.leftColor = "#230db5";
        this.rightColor = "rgba(10, 0, 81, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].S_judge_balance) {
            this.qgData.push(this.analyseValue[i].S_judge_balance[0]);
            this.analyseData.push(this.analyseValue[i].S_judge_balance[1]);
            this.qgHeight.push(this.analyseValue[i].S_judge_balance_kd[0]);
            this.ysHeight.push(this.analyseValue[i].S_judge_balance_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 14) {
        this.leftColor = "#14c0de";
        this.rightColor = "rgba(24, 130, 148, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].S_judge_ctrlHand[0]);
            this.analyseData.push(this.analyseValue[i].S_judge_ctrlHand[1]);
            this.qgHeight.push(this.analyseValue[i].S_judge_ctrlHand_kd[0]);
            this.ysHeight.push(this.analyseValue[i].S_judge_ctrlHand_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      } else if (index == 15) {
        this.leftColor = "#159c56";
        this.rightColor = "rgba(15, 193, 100, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].S_judge_ctrlFoot[0]);
            this.analyseData.push(this.analyseValue[i].S_judge_ctrlFoot[1]);
            this.qgHeight.push(this.analyseValue[i].S_judge_ctrlFoot_kd[0]);
            this.ysHeight.push(this.analyseValue[i].S_judge_ctrlFoot_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
        }
      }
      this.drawAnalyseLine("analyseChart");
    },
    Mouseover1(index) {
      this.mouthAnalyseType = index;
    },
    Mouseleave1() {
      this.mouthAnalyseType = -1;
    },
    drawBar(id) {
      if (this.charts == null) {
        this.charts = echarts.init(document.getElementById(id));
      }
      this.charts.setOption({
        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        radar: {
          shape: "circle",
          indicator: [
            {
              name: "身高\nCM",
              max: 100,
            },
            { name: "体重\nKG", max: 100 },
            { name: "视力", max: 100 },
            { name: "臂展\nCM", max: 100 },
            { name: "肺活量\nML", max: 100 },
            { name: "胸围\nCM", max: 100 },
            { name: "体前屈\nCM", max: 100 },
            { name: "跑动\nS", max: 100 },
            { name: "跳跃\nS", max: 100 },
            { name: "敏捷\nS", max: 100 },
            { name: "力量\nS", max: 100 },
            { name: "协调\nS", max: 100 },
            { name: "平衡\nS", max: 100 },
            { name: "物控手\n个", max: 100 },
            { name: "物控脚\n个", max: 100 },
          ],
          center: ["50%", "50%"],
          radius: "60%",
          name: {
            color: "#CACACA",
          },
          splitNumber: 2,
          axisLine: {
            lineStyle: {
              color: "#D1D1D1",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#D1D1D1",
            },
          },
          splitArea: {
            areaStyle: {
              color: "#e8e8e8",
            },
          },
        },
        series: [
          {
            name: "全国",
            type: "radar",
            data: [
              {
                value: this.topQgData,
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#D7D7D7",
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
            lineStyle: {
              width: 2,
              color: "#B0B0B0",
              labelLine: {
                show: true, //隐藏标示线
              },
            },
          },
          {
            name: "身高",
            type: "radar",
            data: [
              {
                value: [
                  this.value1,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#00b29d",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#00b29d",
              },
            },
            lineStyle: {
              width: 2,
              color: "#00b29d",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "体重",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  this.value2,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#88391e",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#88391e",
              },
            },
            lineStyle: {
              width: 2,
              color: "#88391e",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "视力",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  this.value3,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#344f00",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#344f00",
              },
            },
            lineStyle: {
              width: 2,
              color: "#344f00",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "臂展",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  this.value4,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#002055",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#002055",
              },
            },
            lineStyle: {
              width: 2,
              color: "#002055",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "肺活量",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  this.value5,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#005047",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#005047",
              },
            },
            lineStyle: {
              width: 2,
              color: "#005047",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "胸围",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value6,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#500009",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#500009",
              },
            },
            lineStyle: {
              width: 2,
              color: "#500009",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "体前屈",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value7,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#41cbb9",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#41cbb9",
              },
            },
            lineStyle: {
              width: 2,
              color: "#41cbb9",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "跑动",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value8,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#a37d8c",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#a37d8c",
              },
            },
            lineStyle: {
              width: 2,
              color: "#a37d8c",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "跳跃",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value9,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#55242a",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#55242a",
              },
            },
            lineStyle: {
              width: 2,
              color: "#55242a",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "敏捷",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value10,
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#2e7aa2",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#2e7aa2",
              },
            },
            lineStyle: {
              width: 2,
              color: "#2e7aa2",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "力量",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value11,
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#263d69",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#263d69",
              },
            },
            lineStyle: {
              width: 2,
              color: "#263d69",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "协调",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value12,
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#705d17",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#705d17",
              },
            },
            lineStyle: {
              width: 2,
              color: "#705d17",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "平衡",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value13,
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#230db5",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#230db5",
              },
            },
            lineStyle: {
              width: 2,
              color: "#230db5",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "物控手",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value14,
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#14c0de",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#14c0de",
              },
            },
            lineStyle: {
              width: 2,
              color: "#14c0de",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "物控脚",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.value15,
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#159c56",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#159c56",
              },
            },
            lineStyle: {
              width: 2,
              color: "#159c56",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
        ],
      });
    },
    monthClick(index) {
      this.monthType = index;
      this.topQgData = [];
      this.topQgData.push(this.topData[this.monthType - 1].B_judge_height[0]);
      this.topQgData.push(this.topData[this.monthType - 1].B_judge_weight[0]);
      this.topQgData.push(this.topData[this.monthType - 1].B_judge_eye[0]);
      this.topQgData.push(this.topData[this.monthType - 1].B_judge_arm[0]);
      this.topQgData.push(this.topData[this.monthType - 1].B_judge_lung[0]);
      this.topQgData.push(this.topData[this.monthType - 1].B_judge_bust[0]);
      this.topQgData.push(this.topData[this.monthType - 1].B_judge_flexion[0]);
      this.topQgData.push(this.topData[this.monthType - 1].S_judge_run[0]);
      this.topQgData.push(this.topData[this.monthType - 1].S_judge_jump[0]);
      this.topQgData.push(this.topData[this.monthType - 1].S_judge_agile[0]);
      this.topQgData.push(this.topData[this.monthType - 1].S_judge_power[0]);
      this.topQgData.push(
        this.topData[this.monthType - 1].S_judge_coordiate[0]
      );
      this.topQgData.push(this.topData[this.monthType - 1].S_judge_balance[0]);
      this.topQgData.push(this.topData[this.monthType - 1].S_judge_ctrlHand[0]);
      this.topQgData.push(this.topData[this.monthType - 1].S_judge_ctrlFoot[0]);
      this.value1 = this.topData[this.monthType - 1].B_judge_height[1];
      this.value2 = this.topData[this.monthType - 1].B_judge_weight[1];
      this.value3 = this.topData[this.monthType - 1].B_judge_eye[1];
      this.value4 = this.topData[this.monthType - 1].B_judge_arm[1];
      this.value5 = this.topData[this.monthType - 1].B_judge_lung[1];
      this.value6 = this.topData[this.monthType - 1].B_judge_bust[1];
      this.value7 = this.topData[this.monthType - 1].B_judge_flexion[1];
      this.value8 = this.topData[this.monthType - 1].S_judge_run[1];
      this.value9 = this.topData[this.monthType - 1].S_judge_jump[1];
      this.value10 = this.topData[this.monthType - 1].S_judge_agile[1];
      this.value11 = this.topData[this.monthType - 1].S_judge_power[1];
      this.value12 = this.topData[this.monthType - 1].S_judge_coordiate[1];
      this.value13 = this.topData[this.monthType - 1].S_judge_balance[1];
      this.value14 = this.topData[this.monthType - 1].S_judge_ctrlHand[1];
      this.value15 = this.topData[this.monthType - 1].S_judge_ctrlFoot[1];
      this.drawBar("barChart");
    },
    // 一键分析
    clickAnalyse() {
      this.drawAnalyseLine("analyseChart");
    },
    // 分析柱状图
    drawAnalyseLine(id) {
      if (this.analyseCharts == null) {
        this.analyseCharts = echarts.init(document.getElementById(id));
      }
      var that=this
      this.analyseCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          orient: "vertical",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 0, 0, 0],
          data: ["全国", "园所"],
        },
        grid: {
          top: "50px",
          left: "0px",
          right: "0px",
          bottom: "10px",
        },
        xAxis: {
          type: "category",
          data: this.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: true, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            max:10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.qgHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.leftColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    return that.qgData[data.dataIndex];
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.ysHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.rightColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    if(!that.analyseData[data.dataIndex]){
                      return "0.0"
                    }
                    return that.analyseData[data.dataIndex];
                  },
                },
              },
            },
          },
        ],
      });
    },

    getTopData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.sportMethodRadarData(data).then((res) => {
        this.topData = res.data;
        console.log(this.topData);
        this.topQgData.push(this.topData[0].B_judge_height[0]);
        this.topQgData.push(this.topData[0].B_judge_weight[0]);
        this.topQgData.push(this.topData[0].B_judge_eye[0]);
        this.topQgData.push(this.topData[0].B_judge_arm[0]);
        this.topQgData.push(this.topData[0].B_judge_lung[0]);
        this.topQgData.push(this.topData[0].B_judge_bust[0]);
        this.topQgData.push(this.topData[0].B_judge_flexion[0]);
        this.topQgData.push(this.topData[0].S_judge_run[0]);
        this.topQgData.push(this.topData[0].S_judge_jump[0]);
        this.topQgData.push(this.topData[0].S_judge_agile[0]);
        this.topQgData.push(this.topData[0].S_judge_power[0]);
        this.topQgData.push(this.topData[0].S_judge_coordiate[0]);
        this.topQgData.push(this.topData[0].S_judge_balance[0]);
        this.topQgData.push(this.topData[0].S_judge_ctrlHand[0]);
        this.topQgData.push(this.topData[0].S_judge_ctrlFoot[0]);
        this.value1 = this.topData[0].B_judge_height[1];
        this.value2 = this.topData[0].B_judge_weight[1];
        this.value3 = this.topData[0].B_judge_eye[1];
        this.value4 = this.topData[0].B_judge_arm[1];
        this.value5 = this.topData[0].B_judge_lung[1];
        this.value6 = this.topData[0].B_judge_bust[1];
        this.value7 = this.topData[0].B_judge_flexion[1];
        this.value8 = this.topData[0].S_judge_run[1];
        this.value9 = this.topData[0].S_judge_jump[1];
        this.value10 = this.topData[0].S_judge_agile[1];
        this.value11 = this.topData[0].S_judge_power[1];
        this.value12 = this.topData[0].S_judge_coordiate[1];
        this.value13 = this.topData[0].S_judge_balance[1];
        this.value14 = this.topData[0].S_judge_ctrlHand[1];
        this.value15 = this.topData[0].S_judge_ctrlFoot[1];
        for (let i = 0; i < this.topData.length; i++) {
          this.monthList.push(this.topData[i].time);
        }
        this.$nextTick(function () {
          this.drawBar("barChart");
        });
      });
    },
    // 获取评分数据
    getAvgData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.sportMethodScoreData(data).then((res) => {
        this.rateData = res.data;
      });
    },
    // 获取分析数据
    getAnalyseData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.sportMethodAnalyseData(data).then((res) => {
        this.analyseValue = res.data;
        var result = JSON.parse(res.message);
        this.ysName = result.message;
        this.message = result.analy;
        for (let i = 0; i < this.analyseValue.length; i++) {
          if (this.analyseValue[i].B_judge_height) {
            this.qgData.push(this.analyseValue[i].B_judge_height[0]);
            this.analyseData.push(this.analyseValue[i].B_judge_height[1]);
            this.qgHeight.push(this.analyseValue[i].B_judge_height_kd[0]);
            this.ysHeight.push(this.analyseValue[i].B_judge_height_kd[1]);
          } else {
            this.qgData.push("");
            this.analyseData.push("");
            this.qgHeight.push("");
            this.ysHeight.push("");
          }
          this.times.push(this.analyseValue[i].time);
        }
        this.$nextTick(function () {
          this.drawAnalyseLine("analyseChart");
        });
      });
    },
  },
  mounted() {
    this.getTopData();
    this.getAvgData();
    this.getAnalyseData();
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.topBar {
  width: 654px;
  height: 651px;
  margin-top: -30px;
}
.ys_name {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #606060;
  margin-top: 20px;
}
.row {
  display: flex;
  flex-direction: row;
}
.column1 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.column2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ys_top {
  width: 74px;
  height: 19px;
  border: 2px solid #bbbbbb;
  border-radius: 11px;
}
.qg_top {
  width: 98px;
  height: 19px;
  border: 2px solid #bbbbbb;
  border-radius: 11px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.h240 {
  height: 240px;
}
.mt21 {
  margin-top: 21px;
}
.mt43 {
  margin-top: 43px;
}
.mrl100 {
  margin-left: 100px;
}
.month_normal {
  width: 59px;
  height: 20px;
  border: 3px solid #d4d4d4;
  border-radius: 13px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .text1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b0b0b0;
  }
  .text2 {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b0b0b0;
    margin-top: 2px;
  }
}
.month_select {
  width: 59px;
  height: 20px;
  border: 3px solid #41cbb9;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .text1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #41cbb9;
  }
  .text2 {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #41cbb9;
    margin-top: 2px;
  }
}
.mt2 {
  margin-top: 2px;
}
.ml55 {
  margin-left: 55px;
}

.ys_text {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-top: 2px;
  color: #606060;
}
.mrl9 {
  margin-left: 9px;
}
.mrl16 {
  margin-left: 16px;
}
.mt14 {
  margin-top: 14px;
}
.pos {
  position: relative;
  width: 700px;
  height: 87px;
}
.top_line {
  position: absolute;
  left: 0;
  bottom: 13px;
  width: 689px;
  height: 2px;
  background: #b0b0b0;
}
.line_row {
  position: absolute;
  left: 0;
  top: 0px;
  display: flex;
  flex-direction: row;
  .fk {
    width: 12px;
    height: 5px;
    background: #b0b0b0;
    margin-top: 6px;
  }
}
.mrl35 {
  margin-left: 35px;
}
.title {
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.pes {
  width: 150px;
  height: 150px;
  margin-top: 21px;
}
.content {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.mt15 {
  margin-top: 15px;
}
.baseLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.mt72 {
  margin-top: 72px;
}
.width514 {
  width: 514px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top_lay {
    position: relative;
    width: 100%;
    height: 78px;
  }
  .top_bg {
    width: 498px;
    height: 62px;
    background: #e7e7e7;
    border: 8px solid #ffffff;
    border-radius: 39px;
  }
  .left_text {
    width: 120px;
    height: 62px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .middle {
    width: 100%;
    height: 100%;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pos_abs {
    position: absolute;
    left: 0;
    top: 0;
  }
  .marl8 {
    margin-left: 8px;
  }
  .hint1 {
    font-size: 21px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 25px;
  }
  .left_lay {
    width: 170px;
    height: 49px;
    background: #0abda3;
    border: 6px solid #ffffff;
    border-radius: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 38px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .text1 {
      font-size: 38px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-left: 4px;
    }
    .text2 {
      width: 5px;
      height: 5px;
      background: #ffffff;
      border-radius: 50%;
      margin-left: 15px;
      margin-top: 30px;
    }
  }
  .num_lay {
    width: 514px;
    height: 61px;
    background: #f2f2f2;
    border-radius: 31px;
    display: flex;
    flex-direction: row;
  }
  .mt16 {
    margin-top: 16px;
  }
  .mt36 {
    margin-top: 36px;
  }
}
.column_num {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  margin-right: 6px;
  .text {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    margin-top: 10px;
  }
  .line {
    width: 130px;
    height: 2px;
    background: #b9b9b9;
  }
  .value {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #0abda3;
    margin-top: 2px;
  }
  .vs {
    width: 39px;
    height: 25px;
    margin-top: 6px;
  }
}
.flex {
  flex: 1;
}
.analyseBar {
  width: 1400px;
  height: 561px;
  margin-top: 63px;
}
.tz_select1 {
  width: 100px;
  height: 46px;
  background: #000f60;
  border: 6px solid #0001c1;
  border-top-left-radius: 29px;
  border-bottom-left-radius: 29px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tz_normal1 {
  width: 100px;
  height: 46px;
  background: #e8e8e8;
  border-top: 6px solid #c4c4c4;
  border-left: 6px solid #c4c4c4;
  border-bottom: 6px solid #c4c4c4;
  border-top-left-radius: 29px;
  border-bottom-left-radius: 29px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_select1 {
  width: 100px;
  height: 46px;
  background: #000f60;
  border: 6px solid #0001c1;
  border-top-right-radius: 29px;
  border-bottom-right-radius: 29px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_normal1 {
  width: 100px;
  height: 46px;
  background: #e8e8e8;
  border-top: 6px solid #c4c4c4;
  border-right: 6px solid #c4c4c4;
  border-bottom: 6px solid #c4c4c4;
  border-top-right-radius: 29px;
  border-bottom-right-radius: 29px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mrl10 {
  margin-left: 10px;
}
.qg_flag {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000f5a;
  margin-left: 3px;
}
.qg_text {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000f5a;
  margin-left: 5px;
}
.top_text {
  width: 100%;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding-left: 850px;
  margin-top: 30px;
}
.analyse_month_select {
  width: 59px;
  height: 20px;
  border: 3px solid #000f5a;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .text1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000f5a;
  }
  .text2 {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000f5a;
    margin-top: 2px;
  }
}
.empty_text {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #da3c2d;
}
.result {
  width: 500px;
  margin-top: 26px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
.mt6 {
  margin-top: 6px;
}
.text_normal {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000e56;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text_select {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.width62 {
  width: 72px;
  height: 32px;
  border-radius: 18px;
  cursor: pointer;
}
.mrl16 {
  margin-left: 16px;
}
.ys_color1 {
  background-color: rgba(192, 40, 27, 1);
}
.ys_color2 {
  background-color: rgba(64, 209, 0, 1);
}
.ys_color3 {
  background-color: rgba(0, 168, 193, 1);
}
.ys_color4 {
  background-color: rgba(224, 200, 0, 1);
}
.ys_color5 {
  background-color: rgba(244, 138, 0, 1);
}
.ys_color6 {
  background-color: rgba(39, 0, 237, 1);
}
.ys_color7 {
  background-color: rgba(214, 0, 253, 1);
}
.ys_color8 {
  background-color: rgba(151, 32, 79, 1);
}
.ys_color9 {
  background-color: rgba(150, 36, 49, 1);
}
.ys_color10 {
  background-color: rgba(46, 162, 122, 1);
}
.ys_color11 {
  background-color: rgba(31, 68, 135, 1);
}
.ys_color12 {
  background-color: rgba(182, 150, 35, 1);
}
.ys_color13 {
  background-color: rgba(10, 0, 81, 1);
}
.ys_color14 {
  background-color: rgba(24, 130, 148, 1);
}
.ys_color15 {
  background-color: rgba(15, 193, 100, 1);
}
.mrl9 {
  margin-left: 9px;
}
.alpha {
  background: #e7e7e7;
}
.ml32 {
  margin-left: 12px;
}
.analyse_tab {
  width: 130px;
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .text1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
  }
  .text2 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #686868;
  }
}
.width1400 {
  width: 1400px;
}
.mt26 {
  margin-top: 26px;
}
.ml20 {
  margin-left: 12px;
}
.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.red_text {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce2323;
  margin-top: 20px;
}
</style>